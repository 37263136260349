import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'

import FormLabel from '@/components/FormLabel'
import Input from '@/components/Input'
import RadioGroup from '@/components/RadioGroup'
import Select from '@/components/Select'
import {
  BatteryClassification,
  BatteryPacking,
  BatteryType,
  BatteryTypeIon,
  BatteryTypeMetal,
  BatteryTypeNonLithium,
  BatteryTypesIon,
  BatteryTypesMetal,
  batteryWeightUnits,
  BatteryWeight,
  WeightUnit,
} from '@/gateways/api/models/Package'

import YesNoSelect from './YesNoSelect'

export type BatteryClassificationRadioValue =
  | Extract<BatteryClassification, 'lithium_battery' | 'button_cell'>
  | 'other'

export type BatteryClassificationSelectValue = Extract<
  BatteryClassification,
  | 'alkaline'
  | 'lead_acid'
  | 'nicd'
  | 'nimh'
  | 'silver_oxide'
  | 'zinc_air'
  | 'zinc_carbon'
  | 'not_specified'
>

const batteriesRequiringComplianceReview: BatteryClassification[] = [
  'lithium_battery',
  'button_cell',
  'lead_acid',
  'nicd',
]

const batteryClassificationOtherOptions: Record<BatteryClassificationSelectValue, string> = {
  alkaline: 'Alkaline (Dry Cell)',
  lead_acid: 'Lead-Acid (Car Batteries)',
  nicd: 'Nickel-Cadmium or NiCd (Storage Batteries)',
  nimh: 'Nickel Metal Hydride or NiMH',
  silver_oxide: 'Silver Oxide (Small Button Cells)',
  zinc_air: 'Zinc-Air (for Hearing Aids)',
  zinc_carbon: 'Zinc-Carbon (Dry Cell)',
  not_specified: 'Not Specified',
}

const batteryPackingOptions: Record<BatteryPacking, string> = {
  itself_alone: 'Battery by itself/alone',
  packed_with_equipment: 'Battery packed with equipment',
  contained_in_equipment: 'Battery contained in equipment',
}

const batteryTypeOptions: Record<BatteryType, string> = {
  lithium_ion_cell: 'Lithium Ion - Cells',
  lithium_ion_battery: 'Lithium Ion - Battery',
  lithium_metal_cell: 'Lithium Metal - Cells',
  lithium_metal_battery: 'Lithium Metal - Battery',
  non_lithium_batteries_not_restricted: 'Non-lithium Batteries - Not Restricted',
  non_lithium_batteries_restricted: 'Non-lithium Batteries - Restricted',
}

interface WeightEdit {
  value: number | ''
  unit: WeightUnit
}

interface BatteryCellEdit {
  lithiumContent: number | ''
  wattHours: number | ''
  totalWeight: WeightEdit
}

export interface BatteryCellIon {
  lithiumContent: ''
  wattHours: number
  totalWeight: BatteryWeight
}

export interface BatteryCellMetal {
  lithiumContent: number
  wattHours: ''
  totalWeight: BatteryWeight
}

export interface BatteryEditFormData {
  itemId: string
  itemDescription: string
  batteryPresent: boolean | undefined
  classification: BatteryClassification | ''
  classificationComment: string
  requiresComplianceReview: boolean | undefined
  packing: BatteryPacking | ''
  type: BatteryType | ''
  batteriesCells: BatteryCellEdit[]
  movementSecured: boolean | undefined
}

export interface BatteryFormDataNoBattery {
  itemId: string
  itemDescription: string
  batteryPresent: false
  classification: ''
  classificationComment: ''
  requiresComplianceReview: undefined
  packing: ''
  type: ''
  batteriesCells: []
  movementSecured: undefined
}

export interface BatteryFormDataNoComplianceReview {
  itemId: string
  itemDescription: string
  batteryPresent: true
  classification: BatteryClassification
  classificationComment: string
  requiresComplianceReview: false
  packing: ''
  type: ''
  batteriesCells: []
  movementSecured: undefined
}

export interface BatteryFormDataIonType {
  itemId: string
  itemDescription: string
  batteryPresent: true
  classification: BatteryClassification
  classificationComment: string
  requiresComplianceReview: true
  packing: BatteryPacking
  type: BatteryTypeIon
  batteriesCells: BatteryCellIon[]
  movementSecured: boolean
}

export interface BatteryFormDataMetalType {
  itemId: string
  itemDescription: string
  batteryPresent: true
  classification: BatteryClassification
  classificationComment: string
  requiresComplianceReview: true
  packing: BatteryPacking
  type: BatteryTypeMetal
  batteriesCells: BatteryCellMetal[]
  movementSecured: boolean
}

export interface BatteryFormDataNonLithiumType {
  itemId: string
  itemDescription: string
  batteryPresent: true
  classification: BatteryClassification
  classificationComment: string
  requiresComplianceReview: true
  packing: BatteryPacking
  type: BatteryTypeNonLithium
  batteriesCells: []
  movementSecured: boolean
}

export type BatteryFormData =
  | BatteryFormDataNoBattery
  | BatteryFormDataNoComplianceReview
  | BatteryFormDataIonType
  | BatteryFormDataMetalType
  | BatteryFormDataNonLithiumType

const DEFAULT_BATTERY_CELL: BatteryCellEdit = {
  wattHours: '',
  lithiumContent: '',
  totalWeight: { value: '', unit: 'lb' },
}

const defaultBatteryFormData: BatteryEditFormData = {
  itemId: '',
  itemDescription: '',
  batteryPresent: undefined,
  classification: '',
  classificationComment: '',
  requiresComplianceReview: undefined,
  packing: '',
  type: '',
  batteriesCells: [],
  movementSecured: undefined,
}

interface BatteryCellFieldsProps {
  batteriesCells: BatteryCellEdit[]
  batteryType: BatteryType
  onChange: (batteriesCells: BatteryCellEdit[]) => void
}

function BatteryCellFields({ batteriesCells, batteryType, onChange }: BatteryCellFieldsProps) {
  const isBatteryTypeIon = (BatteryTypesIon as ReadonlyArray<string>).includes(batteryType)
  const isBatteryTypeMetal = (BatteryTypesMetal as ReadonlyArray<string>).includes(batteryType)

  function updateBatteryCell(atIndex: number, newBatteryCell: BatteryCellEdit) {
    const newBatteriesCells = batteriesCells.map((batteryCell, index) => {
      if (index === atIndex) return newBatteryCell
      return batteryCell
    })
    onChange(newBatteriesCells)
  }

  function addBatteryCell() {
    onChange([...batteriesCells, DEFAULT_BATTERY_CELL])
  }

  function handleRemoveBatteryRow(index: number) {
    onChange([...batteriesCells.slice(0, index), ...batteriesCells.slice(index + 1)])
  }

  useEffect(() => {
    if (batteriesCells.length === 0) {
      addBatteryCell()
    }
  }, [batteriesCells])

  return (
    <FormLabel
      label={
        <>
          Batteries / Cells{' '}
          <Typography component="span" color="ink.light">
            (Quantity: {batteriesCells.length})
          </Typography>
        </>
      }
      noControl
    >
      <Stack spacing={2}>
        {batteriesCells.map((batteryCell, index) => (
          <Stack key={index} direction="row" spacing={2}>
            <span>#{index + 1}</span>
            {isBatteryTypeMetal && (
              <Input
                type="number"
                label="Lithium Content"
                unit="g"
                placeholder="0"
                value={batteryCell.lithiumContent}
                onChange={(e) =>
                  updateBatteryCell(index, {
                    ...batteryCell,
                    lithiumContent: parseFloat(e.target.value) || '',
                  })
                }
              />
            )}
            {isBatteryTypeIon && (
              <Input
                type="number"
                label="Watt Hours"
                unit="Wh"
                placeholder="0"
                value={batteryCell.wattHours}
                onChange={(e) =>
                  updateBatteryCell(index, {
                    ...batteryCell,
                    wattHours: parseFloat(e.target.value) || '',
                  })
                }
              />
            )}
            <Input
              type="number"
              label="Total Weight"
              placeholder="0"
              sx={{ '& .MuiInputBase-adornedEnd': { p: 0 } }}
              value={batteryCell.totalWeight.value}
              onChange={(e) =>
                updateBatteryCell(index, {
                  ...batteryCell,
                  totalWeight: {
                    value: parseFloat(e.target.value) || '',
                    unit: batteryCell.totalWeight.unit,
                  },
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      aria-label="total weight unit"
                      noPlaceholder
                      sx={{ border: 0 }}
                      value={batteryCell.totalWeight.unit}
                      onChange={(e) =>
                        updateBatteryCell(index, {
                          ...batteryCell,
                          totalWeight: {
                            value: batteryCell.totalWeight.value,
                            unit: e.target.value as WeightUnit,
                          },
                        })
                      }
                    >
                      {batteryWeightUnits.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
            {batteriesCells.length > 1 && (
              <IconButton
                onClick={() => handleRemoveBatteryRow(index)}
                aria-label="delete battery row"
                sx={{ alignSelf: 'end' }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        ))}
        <Divider />
        <Button onClick={addBatteryCell} sx={{ alignSelf: 'flex-end' }}>
          + add battery / cell
        </Button>
      </Stack>
    </FormLabel>
  )
}

interface FormActionsProps {
  disableSubmit: boolean
  onReset: () => void
  onSubmit: () => void
}

function FormActions({ disableSubmit, onReset, onSubmit }: FormActionsProps) {
  return (
    <Stack direction="row" spacing={2}>
      <Button onClick={onReset} variant="contained" color="secondary" sx={{ height: 45 }} fullWidth>
        reset
      </Button>
      <Button
        onClick={onSubmit}
        variant="contained"
        sx={{ height: 45 }}
        disabled={disableSubmit}
        fullWidth
      >
        next
      </Button>
    </Stack>
  )
}

interface BatteryFormProps {
  initialFormData?: Partial<BatteryEditFormData>
  onChange?: (formData: BatteryEditFormData) => void
  onSubmit?: (formData: BatteryFormData) => void
}

export default function BatteryForm({
  initialFormData = {},
  onChange,
  onSubmit,
}: BatteryFormProps) {
  const initialFormDataRef = useRef({
    ...defaultBatteryFormData,
    ...initialFormData,
  })
  const [formData, setFormData] = useState<BatteryEditFormData>(initialFormDataRef.current)
  const [isOtherBatterySelectVisible, setIsOtherBatterySelectVisible] = useState(false)
  const [isOtherBatteryInputVisible, setIsOtherBatteryInputVisible] = useState(false)

  const isBatteryTypeIon = (BatteryTypesIon as ReadonlyArray<string>).includes(formData.type)
  const isBatteryTypeMetal = (BatteryTypesMetal as ReadonlyArray<string>).includes(formData.type)

  const areBatteryCellsValid = formData.batteriesCells.every((cell) => {
    if (isBatteryTypeIon) {
      return cell.wattHours !== '' && cell.totalWeight.value !== ''
    }
    if (isBatteryTypeMetal) {
      return cell.lithiumContent !== '' && cell.totalWeight.value !== ''
    }
    return true
  })

  function isFormValid() {
    if (formData.batteryPresent === false) {
      return true
    }

    if (
      formData.batteryPresent &&
      formData.classification !== '' &&
      formData.requiresComplianceReview !== undefined &&
      (!formData.requiresComplianceReview ||
        (formData.packing !== '' &&
          formData.type !== '' &&
          areBatteryCellsValid &&
          formData.movementSecured !== undefined)) &&
      (!isOtherBatteryInputVisible || formData.classificationComment !== '')
    ) {
      return true
    }
  }

  function resetForm() {
    updateFormData(initialFormDataRef.current)
    setIsOtherBatterySelectVisible(false)
    setIsOtherBatteryInputVisible(false)
  }

  function updateFormData(updates: Partial<BatteryEditFormData>) {
    setFormData({ ...formData, ...updates })
    onChange?.({ ...formData, ...updates })
  }

  function handleBatteryClassificationChange(_: React.ChangeEvent<HTMLInputElement>, v: string) {
    const value = v as BatteryClassificationRadioValue
    const newFormData = { ...formData }

    setIsOtherBatteryInputVisible(false)
    if (value === 'other') {
      setIsOtherBatterySelectVisible(true)
      newFormData.classification = ''
      newFormData.requiresComplianceReview = undefined
      newFormData.batteriesCells = []
      newFormData.type = ''
      newFormData.packing = ''
      newFormData.movementSecured = undefined
    } else {
      newFormData.requiresComplianceReview = doesBatteryRequiresComplianceReview(value)
      setIsOtherBatterySelectVisible(false)
      newFormData.classification = value
    }
    newFormData.classificationComment = ''
    updateFormData(newFormData)
  }

  function handleOtherBatteryClassificationChange(e: SelectChangeEvent) {
    const value = e.target.value as BatteryClassificationSelectValue
    const newFormData = { ...formData }

    newFormData.requiresComplianceReview = doesBatteryRequiresComplianceReview(value)
    if (value === 'not_specified') {
      setIsOtherBatteryInputVisible(true)
      newFormData.requiresComplianceReview = undefined
    } else {
      setIsOtherBatteryInputVisible(false)
    }
    newFormData.classificationComment = ''
    newFormData.classification = value
    updateFormData(newFormData)
  }

  function doesBatteryRequiresComplianceReview(battery: BatteryClassification) {
    return !!battery && batteriesRequiringComplianceReview.includes(battery)
  }

  function handleBatteryPresentChange(
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean | undefined,
  ) {
    const newFormData = { ...formData }
    newFormData.batteryPresent = value

    if (!value) {
      newFormData.classification = ''
      newFormData.classificationComment = ''
      newFormData.requiresComplianceReview = undefined
      newFormData.packing = ''
      newFormData.type = ''
      newFormData.batteriesCells = []
      newFormData.movementSecured = undefined
      setIsOtherBatterySelectVisible(false)
      setIsOtherBatteryInputVisible(false)
    }
    updateFormData(newFormData)
  }

  function handleRequiresComplianceReviewChange(
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean | undefined,
  ) {
    const newFormData = { ...formData }
    newFormData.requiresComplianceReview = value

    function doesNotRequirecompliance(
      formData: BatteryEditFormData,
    ): formData is BatteryFormDataNoComplianceReview {
      return formData.requiresComplianceReview === false
    }

    if (doesNotRequirecompliance(newFormData)) {
      newFormData.packing = ''
      newFormData.type = ''
      newFormData.batteriesCells = []
      newFormData.movementSecured = undefined
    }
    updateFormData(newFormData)
  }

  function handleBatteryTypeChange(e: SelectChangeEvent) {
    const value = e.target.value as BatteryType
    const newFormData = { ...formData }
    newFormData.type = value
    newFormData.batteriesCells = []
    updateFormData(newFormData)
  }

  return (
    <Stack spacing={3} sx={{ my: 3 }} component="form">
      <Input label="Item ID" value={formData.itemId} disabled />
      <Input label="Item Description" value={formData.itemDescription} disabled />
      <YesNoSelect
        label="Battery Present?"
        value={formData.batteryPresent}
        onChange={handleBatteryPresentChange}
      />
      {formData.batteryPresent && (
        <>
          <RadioGroup
            label="Battery Classification"
            value={formData.classification}
            onChange={handleBatteryClassificationChange}
            sx={{ justifyContent: 'space-between' }}
            row
          >
            <FormControlLabel label="Lithium Battery" value="lithium_battery" control={<Radio />} />
            <FormControlLabel label="Button Cell" value="button_cell" control={<Radio />} />
            <FormControlLabel
              label="Other"
              value="other"
              control={<Radio />}
              checked={isOtherBatterySelectVisible}
            />
          </RadioGroup>
          {isOtherBatterySelectVisible && (
            <Select
              value={formData.classification}
              onChange={handleOtherBatteryClassificationChange}
              aria-label="battery classification other"
            >
              {Object.entries(batteryClassificationOtherOptions).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
          {isOtherBatteryInputVisible && (
            <Input
              placeholder="Specify battery classification"
              value={formData.classificationComment}
              onChange={(e) => updateFormData({ classificationComment: e.target.value })}
            />
          )}
          <YesNoSelect
            label="Requires Compliance Review?"
            value={formData.requiresComplianceReview}
            onChange={handleRequiresComplianceReviewChange}
            disabled={formData.classification !== 'not_specified'}
          />
        </>
      )}
      {formData.requiresComplianceReview && (
        <>
          <RadioGroup
            label="Select one of the following:"
            value={formData.packing}
            onChange={(_, v) => updateFormData({ packing: v as BatteryPacking })}
          >
            {Object.entries(batteryPackingOptions).map(([value, label]) => (
              <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
          <Select label="Battery Type" value={formData.type} onChange={handleBatteryTypeChange}>
            {Object.entries(batteryTypeOptions).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {formData.type && (isBatteryTypeIon || isBatteryTypeMetal) && (
            <BatteryCellFields
              batteriesCells={formData.batteriesCells}
              batteryType={formData.type}
              onChange={(batteriesCells) => updateFormData({ batteriesCells })}
            />
          )}
          <YesNoSelect
            label="Is equipment and battery secured from movement within parcel packaging?"
            value={formData.movementSecured}
            onChange={(_, v) => updateFormData({ movementSecured: v })}
          />
        </>
      )}
      <FormActions
        onReset={resetForm}
        onSubmit={() => onSubmit?.(formData as BatteryFormData)}
        disableSubmit={!isFormValid()}
      />
    </Stack>
  )
}
